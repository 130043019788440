<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="600">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Event</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
               <v-text-field
                 v-model="event.name"
                 :disabled="IS_DISABLED"
                  label="Event Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                <v-select label="Category"  outlined
                  dense v-model="event.category" :items="['BASKETBALL', 'DERBY']"/>
                 <v-text-field
                 v-model="event.venue"
                  label="Venue"
                  :disabled="IS_DISABLED"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                <v-text-field
                 v-model="event.percentage"
                  label="Percentage"
                 :disabled="IS_DISABLED"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                <v-text-field
                type="number"
                 v-model="event.num_fights"
                  label="No. of fights"
                 :disabled="IS_DISABLED"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                 <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Event Date"
                                 :value="event.event_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="event.event_date" no-title scrollable @input="date_menu=false" />
                    </v-menu>

                    <v-combobox
                    clearable
                    dense
                                outlined
                    v-model="event.picks"
                    multiple
                    label="Enable Picks"
                    :items="['PICK 1', 'PICK 2', 'PICK 3', 'PICK 4', 'PICK 5']"
                    ></v-combobox>

                    <v-combobox
                    dense
                                outlined
                    v-model="event.brackets"
                    clearable
                    multiple
                    label="Enable Brackets"
                    :items="brackets"
                    item-text="group"
                    return-object
                    ></v-combobox>

                    <v-textarea label="Event Guidelines/Description" outlined v-model="event.description"></v-textarea>

                  <v-autocomplete
                  v-model="event.status"
                   :rules="textRules"
                   :disabled="IS_DISABLED"
                required
                  :items="['Upcoming','Open','Closed', 'Ready', 'Cancelled']"
                  outlined
                    dense
                  label="Status"
                  ></v-autocomplete>     
                
                </v-col>
              
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    event_id: {
        type: Number,
        default:null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      date_menu: false,
      newpassword:'',
      event:{},
      brackets: [{group:'100H', bet:"100"},{group:'200H', bet:"200"}, {group:'300H', bet:"300"}, {group:'500H', bet:"500"}, {group:'1K', bet:"1000"}, 
      {group:'2K', bet:"2000"},{group:'5K', bet:"5000"}, {group:'10K', bet:"10000"}, {group:'20K', bet:"20000"}],
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      IS_DISABLED(){
          if(this.event.status == "Completed") {
            return true
          }
          return false
      }
  },
  watch: {
    show (v) {
      if(v) {
         if (this.state != 'new') {
          this.getEvent()
        } else {
          this.event = {}
        }
      }
     
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
      getEvent(){
          this.$http.post("event/get", {id: this.event_id}).then(response => {
            response.data.status? this.event = response.data.item:this.event={} 
        }).catch(e => {
          console.log(e)
        })
      },
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, event:this.event})
    }

  }
}
</script>
